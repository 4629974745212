<template>

  <div class="">

    <div class="game-card  mb-2">

      <div class="row small-text mb-0 opacity-8">
        <div class="col-8 text-yellow px-3">
          {{ currentFixture.country }}  •  {{ currentFixture.tournament }}
        </div>
        <div class="col-4 text-purple px-3 text-right">
          <span class=" ml-2"> {{ formatMatchDate(currentFixture.date) }}</span>
        </div>
      </div>

      <div class="row games m-0 p-0">

        <div class="col-1 m-0 p-0 d-none">
          <div class="text-light text-center odd-indicator position-indicator pt-3">
            {{ currentFixture.match_number}}.
          </div>
        </div>

        <div class="col-6 text-light game-card-teams pt-3 m-0 p-0">
          <div class="line-height-1 mb-1 font-size-13">  {{ home_team }} </div>
            <div class="line-height-1 mb-0 font-size-13"> {{ away_team }} </div>
        </div>

        <div
            v-for="(o,i) in currentFixture.outcomes"
            v-bind:key="getKey(o.odd_id)"
            class="col-2 m-0 p-0"
            v-bind:class="getClasses(o)">
          <JackpotOdd
              v-bind:sport_id="currentFixture.sport_id"
              v-bind:match_id="currentFixture.match_id"
              v-bind:market_name="o.market_name"
              v-bind:home_team="home_team"
              v-bind:away_team="away_team"
              v-bind:market_id="o.market_id"
              v-bind:outcome_id="o.outcome_id"
              v-bind:specifier="o.specifier"
              v-bind:active="o.active"
              v-bind:status="o.status"
              v-bind:odds="o.odd"
              v-bind:producer_id="o.producer_id"
              v-bind:producer_status="o.producer_status"
              v-bind:previous_odds="o.previous_odds"
              v-bind:alias="o.alias"
              v-bind:unique="getUnique(i)"
              v-bind:pick="o"/>
        </div>

      </div>

    </div>

  </div>

</template>

<script>

  import JackpotOdd from "./JackpotOdd";

  export default {
    components: {
      JackpotOdd
    },
    name: "JackpotGames",
    props: {
      fixture: {
        required: true
      },
      live: {
        required: false,
        default: false
      },
      current_row: {
        required: true,
        default: 0
      },
    },
    computed: {
      event_time: function () {

        var minutes = parseInt(this.seconds / 60);
        //var seconds = this.seconds % 60;

        var min = minutes;

        if(minutes < 10 ) {

          min = "0"+min;
        }

        return min+"'";

      },
      sport_name: function () {

        return this.getSportName(this.currentFixture.sport_id)
      },
      country_name: function () {

        return this.currentFixture.country
      },
      home_team: function () {

        return this.getHomeCompetitorName(this.currentFixture.name)
      },
      away_team: function () {

        return this.getAwayCompetitorName(this.currentFixture.name)
      },
      sportIcon: function () {

        return this.getSportIcon(this.currentFixture.sport_id);

      },
      sportAlt: function () {
        switch (this.currentFixture.sport_id) {

          case 1:
            return "Soccer Icon";

          case 2:
            return "basketball icon";

          case 4:
            return "hockey icon";

          case 5:
            return "tennis icon";

          case 22:
            return "/assets/icons/dart.svg";

          case 20:
            return "/assets/icons/tabletennis.svg";

          case 6:
            return "/assets/icons/handball.svg";

          case 12:
            return "/assets/icons/rugby.svg";

          case 10:
            return "/assets/icons/boxing.svg";

          case 31:
            return "/assets/icons/badminton.svg";

          case 3:
            return "/assets/icons/baseball.svg";
        }
        return ""
      },
      currentFixture: {
        // getter
        get: function () {

          return this.formattedFixture

        },
        // setter
        set: function (newValue) {

          this.formattedFixture = newValue
        }
      }
    },
    methods: {
      getUnique: function (column){

        return 'r'+this.current_row+'c'+column;

      },
      isLocked: function(o){

        if(parseInt(o.producer_id) === 1 ) {

          return  parseInt(o.status) > 0 || parseInt(o.active) === 0 || parseInt(o.producer_status) === 0;

        } else {

          return  parseInt(o.status) > 0 || parseInt(o.active) === 0;

        }
      },

      getKey: function(index) {

        var prefix = "odds-"+index;
        return Math.random().toString(10).replace('0.', 'odd-id-'+prefix + '-');

      },

      reloadUI: function () {

        var vm = this;

        var betsObject = this.getJackpotBetSlip(0);

        var bets = betsObject.picks;

        vm.jQuery(".picked").removeClass('picked');

        this.checkJackpotSlipCounter(betsObject.total);

        this.$store.dispatch("setJackpotBetSlip", betsObject);

        vm.jQuery.each(bets, function (matchID, b) {

          var matchClass = ".match-" + matchID;
          var oddsClass = "#" + b.odd_id;

          if(vm.jQuery(oddsClass).length) {

            console.log(" exists "+oddsClass);

          } else {

            console.log(" doesn't exists "+oddsClass);

          }

          vm.jQuery(matchClass).removeClass('picked');
          vm.jQuery(oddsClass).addClass('picked');
          vm.jQuery("#betslip-footer").removeClass('hidden');

        });

      },

      getV: function(x){

        return x;

      },
      getHomeCompetitorName: function (matchName) {

        if (matchName === undefined) {

          return "";
        }

        return matchName.split('vs.')[0];
      },
      getAwayCompetitorName: function (matchName) {

        if (matchName === undefined) {

          return "";
        }
        return matchName.split('vs.')[1];
      },
      getClasses: function (o) {

        //var st = parseInt(o.active) === 0 || parseInt(o.status) !== 0 ? 'btn-odd' : 'btn-odd';

        return "" +
                " match-" + this.currentFixture.match_id +
                " producer-" + o.producer_id +
                " match-" + this.currentFixture.match_id +
                " market-" + o.market_id +
                " outcome-" + o.outcome_id +
                " specifier-" + o.specifier;
      },
      matchURL: function () {

        var game_id = this.currentFixture.game_id;
        var name = this.toURL(this.currentFixture.name);

        var url = "/match/" + game_id + "/" + name;
        return url;

      },
      toURL: function (name) {

        if (name === undefined) {

          return "-";
        }

        return name.replace(/[^a-z0-9+]+/gi, '-');

      },
      getOddDirectionClass: function (outcome) {

        if(parseInt(outcome.active) === 0 || parseInt(outcome.status) != 0) {

          return '';
        }

        if(parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds) ) {

          return 'arrow green up';

        }

        return 'arrow red down';

      }
    },
    mounted: function() {

      var vm = this;

      if(Array.isArray(this.fixture)) {

        this.currentFixture = this.fixture[0];

      } else {

        this.currentFixture = this.fixture

      }

      // split to get event time as seconds
      /*if(this.currentFixture.event_time.length > 0 ) {

        var p = this.currentFixture.event_time.split(':');
        var minutes = p[0];
        var seconds = p[1];
        seconds = parseInt(seconds);
        seconds = seconds + parseInt(minutes) * 60;
        this.seconds = seconds;
      }*/


      if(this.currentFixture.match_status === 'NotStarted' ) {
        this.upcoming = true;
        this.isLive = false;
        vm.to_start = vm.timeToStart(vm.currentFixture.date);
      }

    },
    data: function() {
      return {
        color: '',
        seconds: 0,
        to_start: '',
        formattedFixture: '',
        upcoming: '',
        isLive: '',
        odds: '',
        status: '',
        active:'',
      }
    }
  }

</script>